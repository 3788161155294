import * as React from "react";
import Layout from "../components/layout";
import { Link } from "gatsby";

const LocationPage = () => {
  return (
    <Layout>
      <main>
        <div className="container pt-24 md:pt-36">
          <h1 className="text-5xl font-extralight uppercase">Location</h1>
          <div className="flex my-8 rounded-xl overflow-hidden border-8 border-jewel-blue">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2494.692878217291!2d106.6855570451393!3d-6.31383930747196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69e5fe6d547b5d%3A0x723a14f6b728441c!2sJewel%20Residences%20%40%20Serpong!5e0!3m2!1sen!2sid!4v1662659667137!5m2!1sen!2sid"
              className="border-none border-0 !w-full"
              width="600"
              height="450"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <p className="font-extralight text-lg">
            Jl. Kencana V, Rawamekar Jaya Serpong –
            <span className="whitespace-nowrap">Tangerang Selatan</span>
          </p>
        </div>
      </main>
    </Layout>
  );
};

export default LocationPage;

export const Head = () => (
  <>
    <title>JEWEL Residences @ Serpong, Tangerang Selatan - Location</title>
    <meta
      name="description"
      content="Our location: Jl. Kencana V, Rawamekar Jaya Serpong – Tangerang Selatan"
    />
    <meta name="og:url" content="https://www.jewelresidences.com/location/" />
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
      href="https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;700;800&display=swap"
      rel="stylesheet"
    ></link>
    <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=GTM-K3DM7CP"
    ></script>
  </>
);
